import {
  prefixAPI,
  baseAPI,
} from '@resource/api'

const AUTH_MODEL = 'auth'
const APPEAL_MODEL = 'appeal'
const CABINET_MODEL = 'cabinet'
const NOVAPOSHTA_MODEL = 'novaposhta'
const ROZETKA_MODEL = 'rozetka'
const CART_MODEL = 'cart'
const ORDER_MODEL = 'order'

export const AUTH = {
  REGISTRATION: prefixAPI('/registration/', AUTH_MODEL),
  SIGNIN: prefixAPI('/login/', AUTH_MODEL),
  LOGOUT: prefixAPI('/logout/', AUTH_MODEL),
  RESET_PASSWORD: prefixAPI('/password/reset/request/', AUTH_MODEL),
  SET_NEW_PASSWORD: prefixAPI('/password/reset/confirm/', AUTH_MODEL),
  CHANGE_EMAIL: prefixAPI('/change-email/', AUTH_MODEL),
  CHANGE_PASSWORD: prefixAPI('/password/change/', AUTH_MODEL),
  EMAIL_NOT_SET_FORM: baseAPI('/complete/facebook/'),
}

export const CATALOG = {
  CATALOG_FILTERS: prefixAPI('filter-catalog/{category}/{;filters*}/'),
  CATALOG_PROMO_PRODUCT_FILTERS: prefixAPI('filter-stock-prod/{category}/{;filters*}/'),
  CATALOG_SERIES: baseAPI('/series-ajax/{category}/{;filters*}/'),
  CATALOG_LEVELS: baseAPI('/level-ajax/{category}/{series}/{;filters*}/'),
  CATALOG_CARDS: baseAPI('/catalog-ajax/{category}/{;filters*}/page/{page}/'),
  CATALOG_PROMO_PRODUCT_CARDS: baseAPI('/stock-ajax/{category}/{;filters*}/page/{page}/'),
}

export const ACTIONS = {
  FAVORITE_ACTION: prefixAPI('like/turn/'),
  LIKE_BLOG_ACTION: prefixAPI('like-blog/turn/'),
  LIKE_EVENT_ACTION: prefixAPI('like-event/turn/'),
}

export const COMMON = {
  ARTICLES_LIST: baseAPI('/article-ajax/list/{category}/page/{page}/'),
  PROMO_LIST: baseAPI('/stock-ajax/list/{category}/page/{page}/'),
  FAQ_FORM: prefixAPI('question/create/'),
  FAQ_LIST: baseAPI('/faq/list-ajax/{;filters*}/'),
  CONTACT_FORM: prefixAPI('/create/', APPEAL_MODEL),
  SUBSCRIPTION_FORM: prefixAPI('subscribe/create/'),
  SUBSCRIPTION_STOCK_FORM: prefixAPI('subscribe-stock/create/'),
  THEMES_LIST: prefixAPI('/subject-list/', APPEAL_MODEL),
  TRACKED_ADD: prefixAPI('tracker-product/create/'),
  DEFAULT_CITY: prefixAPI('default-city/set/'),
  NEWS_CATEGORIES_LIST: prefixAPI('category-blog/list/'),
  PROMO_CATEGORIES_LIST: prefixAPI('category-stock/list/'),
  WHOLESALE_FORM: prefixAPI('wholesale-appeal/create/'),
}

export const CABINET = {
  USER_DETAIL: prefixAPI('/profile/detail/', CABINET_MODEL),
  USER_UPDATE: prefixAPI('/profile/update/', CABINET_MODEL),
  DELIVERY_DETAIL: prefixAPI('/deliver/detail/', CABINET_MODEL),
  DELIVERY_UPDATE: prefixAPI('/deliver/update/', CABINET_MODEL),
  TRACKED_LIST: baseAPI('/tracker-product/page/{page}/'),
  TRACKED_DELETE: prefixAPI('tracker-product/delete/'),
  ORDERS_LIST: prefixAPI('/order/list/page/{page}/', CABINET_MODEL),
  BONUS: prefixAPI('/bonus/detail/', CABINET_MODEL),
}

export const LOCATIONS = {
  CITIES: prefixAPI('/cities/', NOVAPOSHTA_MODEL),
  WAREHOUSES: prefixAPI('/warehouse/', NOVAPOSHTA_MODEL),
  STREETS: prefixAPI('/streets/', NOVAPOSHTA_MODEL),
  ROZETKA_DEPARTMENTS: prefixAPI('/departments/?city={city}', ROZETKA_MODEL),
}

export const ORDER = {
  CART_LIST: prefixAPI('/retrieve/', CART_MODEL),
  CART_CHANGE: prefixAPI('/change/', CART_MODEL),
  CART_INFO: prefixAPI('/quantity/', CART_MODEL),
  CART_CLEAR: prefixAPI('/clear/', CART_MODEL),
  ORDER_INTIAL_DATA: prefixAPI('/preload/', ORDER_MODEL),
  PAYMENT_LIST: prefixAPI('/payment-list/', ORDER_MODEL),
  ORDER_CREATE: prefixAPI('/create/', ORDER_MODEL),
  ONE_CLICK: prefixAPI('/create-click/', ORDER_MODEL),
  PROMOCODE: prefixAPI('promocode/set/'),
  PRESENTS: prefixAPI('list-present/'),
}

export const SEARCH = {
  HEADER_SEARCH: prefixAPI('search-header/'),
  SEARCH_CATALOG_FILTERS: prefixAPI('filter-search/{;filters*}/'),
  SEARCH_CARDS: baseAPI('/search-ajax/{;filters*}/page/{page}/'),
}

export const REVIEW = {
  LIST: baseAPI('/reviews-ajax/{product}/page/{page}/'),
  CREATE: prefixAPI('review/create/'),
  LIKE: prefixAPI('review/like/turn/'),
}
