import {
  createResource,
  postResource,
  getResource,
} from '@resource/resource'

import { COMMON } from './paths'

export const articlesListResource = createResource(
  COMMON.ARTICLES_LIST, getResource
)

export const promoListResource = createResource(
  COMMON.PROMO_LIST, getResource
)

export const faqFormResource = createResource(
  COMMON.FAQ_FORM, postResource
)

export const faqListResource = createResource(
  COMMON.FAQ_LIST, getResource
)

export const contactFormResource = createResource(
  COMMON.CONTACT_FORM, postResource
)

export const subscriptionFormResource = createResource(
  COMMON.SUBSCRIPTION_FORM, postResource
)

export const subscriptionStockFormResource = createResource(
  COMMON.SUBSCRIPTION_STOCK_FORM, postResource
)

export const themeListResourse = createResource(
  COMMON.THEMES_LIST, getResource
)

export const trackedAddResourse = createResource(
  COMMON.TRACKED_ADD, postResource
)

export const setDefaultCityResource = createResource(
  COMMON.DEFAULT_CITY, postResource
)

export const newsCategoriesListResource = createResource(
  COMMON.NEWS_CATEGORIES_LIST, getResource
)

export const promoCategoriesListResource = createResource(
  COMMON.PROMO_CATEGORIES_LIST, getResource
)

export const wholesaleFormResource = createResource(
  COMMON.WHOLESALE_FORM, postResource
)
