import CartButton from './CartButton'
import BuyButton from './BuyButton'
import FavoriteAction from './FavoriteAction'
import FavoriteButton from './FavoriteButton'
import Logout from './LogOut'
import TrackedButton from './TrackedButton'
import TrackedDelete from './TrackedDelete'
import BuyAction from './BuyAction'
import ProductCardWrapper from './ProductCardWrapper'

const LikeAction = () => import('./LikeAction')

export default function install(Vue) {
  Vue.component(CartButton.name, CartButton)
  Vue.component(BuyButton.name, BuyButton)
  Vue.component(FavoriteAction.name, FavoriteAction)
  Vue.component(FavoriteButton.name, FavoriteButton)
  Vue.component(Logout.name, Logout)
  Vue.component(TrackedButton.name, TrackedButton)
  Vue.component(TrackedDelete.name, TrackedDelete)
  Vue.component(BuyAction.name, BuyAction)
  Vue.component('LikeAction', LikeAction)
  Vue.component('ProductCardWrapper', ProductCardWrapper)
}
