import {
  createResource,
  postResource,
  // getResource,
} from '@resource/resource'

import { ACTIONS } from './paths'

export const turnFavoriteResource = createResource(
  ACTIONS.FAVORITE_ACTION, postResource
)

export const turnBlogLikeResource = createResource(
  ACTIONS.LIKE_BLOG_ACTION, postResource
)

export const turnEventLikeResource = createResource(
  ACTIONS.LIKE_EVENT_ACTION, postResource
)
