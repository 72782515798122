import './public-path'
import './install-helpers'
import '@/js/directives'
import '@/js/validation'

import Vue from 'vue'
import I18n from 'vue-i18n'
import Store from 'vuex'
import Router from 'vue-router'
import renderer from '@app/renderer'
import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'

import { install } from '@/install'
import { createApp } from '@/app'

import './styles/index.sass'

Vue.config.productionTip = false

Vue.use(install)

export const { app } = createApp({
  Vue,
  I18n,
  Store,
  Router,
  renderer,
})

app.$mount('#app')

extend('phone_length', {
  validate(value) {
    const PHONE_REGEXP = /^\+\d{2}\(\d{3}\)\d{3}\s\d{2}\s\d{2}$/
    return PHONE_REGEXP.test(value)
  },
  message() {
    return app._('Input a valid length of phone')
  },
})

/* email without cyrillic name before @ */
extend('custom_email', {
  validate(value) {
    const CUSTOM_EMAIL_REGEXP = /^(([a-zA-Z\-_0-9^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return CUSTOM_EMAIL_REGEXP.test(value)
  },
  message() {
    return app._('The field must be a valid email address.')
  },
})

extend('required', {
  ...rules.required,
  message() {
    return app._('This field is required.')
  },
})

extend('min', {
  ...rules.min,
  message(value, sec) {
    return `${app._('The field must contain at least')} ${sec.length} ${app._('symbols')}`
  },
})

extend('required_if', {
  ...rules.required_if,
  message() {
    return app._('This field is required.')
  },
})

extend('confirmed', {
  ...rules.confirmed,
  message() {
    return app._('Values do not match.')
  },
})

extend('alpha_spaces', {
  ...rules.alpha_spaces,
  message() {
    return app._('The field can contain only letters.')
  },
})

extend('username', {
  validate(value) {
    return /^[a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ `'’-]+$/i.test(value)
  },
  message() {
    return app._('The field can contain only letters.')
  },
})

extend('numeric', {
  ...rules.numeric,
  message() {
    return app._('The field must be a number.')
  },
})
